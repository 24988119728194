import { urls } from "./urls"

export const menuConfig = [
  {
    title: "Know your vulva",
    text: "Can you label the 8 bits that make up your vulva?",
    backgroundColor: props => props.theme.vanilla,
    linkText: "Let’s get labelling",
    link: urls.KYV.QUIZ,
    isOpen: false,
  },
  {
    title: "Love your vulva",
    text:
      "Everyone’s genitalia is unique and we should be celebrating that! There’s no right or wrong, so how do you feel about yours?",
    backgroundColor: props => props.theme.peach,
    linkText: "Take the quiz",
    link: urls.LYV.QUIZ,
    isOpen: false,
  },
  {
    title: "Vulva Health",
    text: "Learn how to maintain a healthy vulva & vagina",
    backgroundColor: props => props.theme.peach,
    linkText: "Take the quiz",
    link: urls.VH.CARDS,
    isOpen: false,
  },
  {
    title: "Vulva Maker",
    text:
      "Different is normal! Build your own unique vulva using the VulvaMaker",
    backgroundColor: props => props.theme.peach,
    linkText: "VulvaMaker",
    link: urls.VM.INTRO,
    isOpen: false,
  },
]
