import React from "react"
import styled from "@emotion/styled"
import Layout from "../../components/global/layout"
import AccordionMenu from "../../components/block/AccordionMenu"
import { menuConfig } from "../../data/menu"

const Grid = styled.div`
  display: grid;
  grid-template-rows: max-content;
  gap: 24px;
  padding: 0 24px 24px;
  overflow: auto;
  height: 100%;
`

export default function LoveYourVulvaIntro() {
  return (
    <Layout>
      <Grid>
        {menuConfig.map((menuItem, i) => (
          <AccordionMenu
            key={"menuItem" + i}
            part={i}
            title={menuItem.title}
            text={menuItem.text}
            backgroundColor={menuItem.backgroundColor}
            linkText={menuItem.linkText}
            link={menuItem.link}
            isOpen={i === 1}
          />
        ))}
      </Grid>
    </Layout>
  )
}
