import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "@emotion/styled"
import { useCookies } from "react-cookie"
import { urls } from "../../data/urls"

const Container = styled.div`
  background: ${props => props.backgroundColor};
  border-radius: ${props => props.theme.radius.xl};
  text-align: center;
  display: grid;
  gap: 24px;
  padding: ${props =>
    props.isOpen ? "24px 32px 32px 32px" : "8px 32px 16px 32px"};
  opacity: ${props => (props.isOpen ? "1" : "0.6")};
`
const Part = styled.div`
  color: ${props => props.theme.wine};
`
const Content = styled.div`
  align-items: center;
`

const Title = styled.h5`
  color: ${props => props.theme.wine};
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin-bottom: 0;
`

const Text = styled.div`
  padding-top: 1rem;
  color: ${props => props.theme.wine};
`
const CustomLink = styled(AniLink)`
  width: 100%;
  border: none;
  border-radius: ${props => props.theme.radius.xl};
  background-color: ${props => props.theme.shade[100]};
  color: ${props => props.backgroundColor};
  padding: 12px 32px;
  cursor: pointer;
  text-decoration: none;
  left: 0;
  right: 0;
  margin: auto auto 0 auto;
`

export default function AccordionMenu({
  part,
  title,
  text,
  link,
  linkText,
  textColor,
  backgroundColor,
  isOpen,
}) {
  const activeEl = useRef(null)

  useEffect(() => {
    if (activeEl.current && isOpen) {
      activeEl.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [activeEl, isOpen])

  const [cookies] = useCookies([])
  const isdemographicSet = cookies.demographic

  return (
    <Container backgroundColor={backgroundColor} isOpen={isOpen} ref={activeEl}>
      <Content isOpen={isOpen}>
        <Part>Part {part + 1}</Part>
        {title && (
          <Title isOpen={isOpen} textColor={textColor}>
            {title}
          </Title>
        )}
        {isOpen && <Text textColor={textColor}>{text}</Text>}
      </Content>
      {link && !isdemographicSet && (
        <CustomLink
          backgroundColor={props => props.theme.rose}
          cover
          bg="#FF6673"
          to={urls.ABOUT}
          state={{
            destination: link,
          }}
        >
          {linkText}
        </CustomLink>
      )}
      {link && isdemographicSet && (
        <CustomLink
          backgroundColor={props => props.theme.rose}
          cover
          bg="#FF6673"
          to={link}
        >
          {linkText}
        </CustomLink>
      )}
    </Container>
  )
}

AccordionMenu.propTypes = {
  part: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.any,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  linkText: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isOpen: PropTypes.bool,
}
